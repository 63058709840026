<template>

    <div class="CustToastCont">
        <div class="CustToast"
            :class="type"
            v-if="showToast">{{ message }}</div>
    </div>

</template>

<script>
    export default {
        name: "CustToast",
        data() {
            return {
                showToast: true,
                type: "normal",
                message: "消息提示",
                duration: 3000
            }
        },
    }
</script>

<style scoped>
    .CustToastCont {
        width: 100vw;
        display: flex;
        position: fixed;
        top: 10%;
    }

    .CustToast {
        margin: auto;
        background: rgb(233, 233, 235);
        padding: 10px 10px;
        border-radius: 5px;
        animation: show-toast .2s;
        color: #909399;
        overflow: hidden;
        display: flex;
        align-items: center;
        font-size: 22px;
    }

    @keyframes show-toast {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .success {
        color: #67C23A;
        background: rgb(225, 243, 216);
    }

    .warning {
        color: #E6A23C;
        background: rgb(250, 236, 216);
    }

    .fail {
        color: #F56C6C;
        background: rgb(253, 226, 226);
    }


</style>
