import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/css/reset.min.css'
Vue.config.productionTip = false;
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import 'lib-flexible/flexible.js'
import showToast from './components/Toast/index'

Vue.use(showToast);
Vue.use(VueAwesomeSwiper, /* { default global options } */)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
